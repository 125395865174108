body#cms {
    #left-column {
        ul {
            li {
                a {
                    line-height: 1.4;
                    margin-bottom: 5px;
                    display: block;
                }
            }
        }
    }

    #wrapper {
        .breadcrumb {
            .page_name {
                display: none;
            }
        }

        #main {
            .page-header {
                display: none;
            }
        }
    }
}