body footer#footer,
body#checkout footer#footer {
    position: relative;
    background: $primary;
    color: $white;
    margin-top: 60px;

    &:before {
        content: "";
        height: 5px;
        background-color: $primary;
        display: block;
        position: absolute;
        width: 100%;
        top: -10px;
    }

    .links li a {
        color: $white;

        &:hover {
            color: $white-hover;
        }
    }

    .displayFooterRight .footer-contact ul li p,
    .displayFooterRight .footer-links span,
    .displayFooterRight .footer-contact ul li p span {
        color: $white;
    }

    .laberFooter-top {
        display: none;
    }

    .laberFooter-center {
        @media screen and (max-width: 1200px) {
            padding: 0 15px;
        }

        .displayFooter {
            min-height: 0px;
            padding: 50px 0 30px;
        }

        #block_myaccount_infos .h3 a {
            color: $white;

            &:hover {
                color: $white-hover;
            }
        }

        @media screen and (max-width: 767px) {
            .links {
                .title {
                    border-color: $white;

                    span.h3 {
                        color: $white;
                        font-size: 1rem;
                    }

                    .float-xs-right .material-icons {
                        color: $white;
                    }
                }
            }
        }
    }

    .laberFooter-bottom {
        padding: 0 0 15px;
        background: $primary;
        border-top: none;

        .developer, .copyright {
            line-height: 30px;
        }

        @media screen and (max-width: 1200px) {
            padding: 0 15px 15px;
        }

        @media screen and (max-width: 767px) {
            text-align: center;
        }

        a {
            color: $white;

            &:hover {
                color: $white-hover;
            }
        }

        .logo-payment {
            height: 30px;
            display: inline-block;

            @media screen and (max-width: 767px) {
                display: block;
                text-align: center;
                float: none;
                margin-top: 5px;
                height: auto;
            }

            #paiementslien {
                height: 30px;
                display: inline-block;
                text-decoration: none;

                @media screen and (max-width: 767px) {
                    height: auto;
                }

                img, span {
                    display: inline-block;
                    height: 30px;
                    line-height: 30px;
                    background: $white;
                    padding: 5px;
                    border-radius: 5px;
                    vertical-align: middle;
                    
                    @media screen and (max-width: 767px) {
                        margin-top: 5px;
                    }
                }

                span {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 600;
                    color: $black;
                    font-size: 18px;
                }
            }
        }
    }
}