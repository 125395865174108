body header#header {
    @media screen and (max-width: 767px) {
        margin-bottom: 0;
    }

    .header-nav {
        #contact-link {
            ul {
                li {
                    padding-left: 20px;

                    a {
                        display: inline-block;
                        color: $gray-dark;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 46px;
                        padding-top: 0;

                        &:hover {
                            color: $primary;
                        }
                    }

                    @media screen and (min-width: 768px) and (max-width: 991px) {
                        margin-right: 10px;

                        i {
                            position: relative;
                            line-height: 46px;
                            margin-right: 5px;

                            &.icon-call-in {
                                padding-top: 0;
                            }
                        }

                        &:first-child {
                            padding: 0;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 767px) {
            background: $primary;

            .top-logo {
                padding: 15px;

                h1, a, picture, img {
                    width: 100%;
                }
            }

            #labermenu-icon {
                color: $white;
            }

            .laber-cart {
                a.cart {
                    color: $white;
                    margin-top: 20px;

                    .cart-products-count {
                        color: $light;
                    }


                }
            }
            
            #_mobile_Search_top {
                width: calc(100% - 60px);

                .search-widget {
                    padding-left: 0;

                    form {
                        input[type="text"] {
                            border-color: $primary;
                            min-width: 0;
                        }
                    }
                }
            }

            .laberwishtlistTop {
                a.wishtlist_top {
                    color: $white;
                    padding-left: 10px;
                    border-left: none;
                    margin-left: 0;
                    margin-right: 0;
                    margin-top: 24px;
                    line-height: 1;
                    font-size: 22px;
    
                    &:before {
                        content: none;
                    }
    
                    .cart-wishlist-number {
                        color: $white;
                        top: -9px;
                    }
                }
            }
    
            .laberUserInfo .laberUser p.nameuser {
                color: $white;
                padding: 0 10px;
                margin-right: 10px;
                line-height: 1;
    
                .icon-user {
                    position: relative;
                    line-height: 1;
                    font-size: 22px;
                    margin-top: 24px;
                    display: block;
                }
    
                span,
                .account {
                    display: none;
                }
    
                a {
                    color: $white;
                }
            }
        }

        @media screen and (max-width: 500px) {
            .top-logo {
                width: 55vw;
            }

            .laber-cart {
                a.cart {
                    margin-top: 15px;
                }
            }

            .laberwishtlistTop {
                a.wishtlist_top {
                    margin-top: 19px;
                    font-size: 22px;
    
                    .cart-wishlist-number {
                        top: -9px;
                    }
                }
            }
    
            .laberUserInfo .laberUser p.nameuser {
                .icon-user {
                    margin-top: 19px;
                }
            }
        }

        @media screen and (max-width: 375px) {
            .laber-cart {
                a.cart {
                    margin-left: 0;
                }
            }
    
            .laberUserInfo .laberUser p.nameuser {
                margin-right: 0;
            }
        }
    }

    .header-top {
        background: $primary;
        color: $white;

        &.laberMenu-top {
            background: $primary !important;
        }

        .top-logo {
            h1, a, picture, img {
                width: 100%;
            }
        }

        .lab-menu-horizontal {
            @media screen and (min-width: 768px) {
                ul {
                    li {
                        a {
                            font-family: $font-family;
                            color: $white;
                        }

                        &:hover {
                            a {
                                color: $white-hover;
                            }
                        }
                    }
                }
            }

            @media screen and (max-width: 767px) {
                .title-menu {
                    background: $primary;
                }
            }
        }

        .laberwishtlistTop {
            a.wishtlist_top {
                color: $white;
                padding-left: 10px;
                border-left: none;
                margin-left: 0;

                &:before {
                    content: none;
                }

                .cart-wishlist-number,
                &:hover {
                    color: $white-hover;
                }
            }
        }

        .laber-cart {
            a.cart {
                color: $white;

                &:hover,
                .cart-products-count {
                    color: $white-hover;
                }
            }

            .dropdown-menu {
                .cart-buttons {
                    a {
                        margin-top: 10px;
                    }
                }
            }
        }

        .laberSearch-dropdown .expand-more {
            color: $white;

            &:hover {
                color: $white-hover;
            }
        }

        .laberUserInfo .laberUser p.nameuser {
            color: $white;
            padding: 0 10px;
            margin-right: 10px;

            .icon-user {
                position: relative;
            }

            &:hover,
            .icon-user:hover {
                color: $white-hover;
            }

            span,
            .account {
                display: none;
            }

            a {
                color: $white;

                &:hover {
                    color: $white-hover;
                }
            }
        }

        @media screen and (max-width: 1409px) and (min-width: 1160px) {
            .position-static {
                max-width: 225px;
            }

            #_desktop_logo {
                max-width: 225px;
            }
        }

        @media screen and (max-width: 1159px) and (min-width: 768px) {
            .position-static {
                width: 33%;
            }

            #_desktop_logo {
                width: 33%;
            }
        }

        @media screen and (max-width: 767px) {
            .mobile_UserInfo .signin {
                background: $primary;
            }
        }

        &:after {
            content: "";
            height: 5px;
            background-color: $primary;
            display: block;
            position: absolute;
            width: 100%;
            margin-top: 5px;
        }

        .container_lab_megamenu {
            @media screen and (max-width: 1159px) {
                clear: both;
            }

            
            .displayMegamenu {
                ul.menu-content {
                    display: flex;
                    width: 100%;
                    position: absolute;
                    justify-content: center;

                    & > li {
                        float: none;

                        @media screen and (max-width: 1799px) {
                            padding: 0 10px;
                        }

                        .menu-dropdown {
                            li {
                                a {
                                    display: none;
                                }
                            }
                        }

                        & > a {
                            &:after {
                                position: relative;
                                top: 3px;
                                margin-left: 5px;
                            }

                            @media screen and (max-width: 1159px) and (min-width: 768px) {
                                line-height: 60px;
                            }
                        }

                        &:hover {
                            .menu-dropdown {
                                li {
                                    a {
                                        display: block;
                                        color: $dark;

                                        &:hover {
                                            text-decoration: none;
                                            color: $primary;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}