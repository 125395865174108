body {
    .laberProductGrid {
        .item-inner {
            .item {
                .laberActions {
                    right: 20px;
                    opacity: 1;
                }

                &:hover {
                    .productName {
                        a {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }

    .laberProductList {
        .laberCart .laberBottom {
            &:hover {
                border-color: $primary;
            }
        }

        .laberItem {
            a {
                &:hover {
                    border-color: $primary;
                }
            }
        }

        .item {
            .description_short {
                &.has-to-show-more {
                    max-height: 200px;
                    position: relative;
                    overflow: hidden;

                    .show-more {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 80px;
                        background: linear-gradient(180deg, rgba($white, .2) 0, rgba($white, .5) 25%, rgba($white, .7) 50%, rgba($white, .9));
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    &.open {
                        max-height: none;

                        .show-more {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}