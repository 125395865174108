body#product {

    .breadcrumb {
        .page_name {
            display: none;
        }
    }

    .description {
        &.has-to-show-more {
            max-height: 200px;
            position: relative;
            overflow: hidden;

            .show-more {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 80px;
                background: linear-gradient(180deg, rgba($white, .2) 0, rgba($white, .5) 25%, rgba($white, .7) 50%, rgba($white, .9));
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &.open {
                max-height: none;

                .show-more {
                    display: none;
                }
            }
        }
    }

    .blockreassurance_product {
        .item-product {
            margin-right: 10px;
        }
    }

    .title_block h3 {
        position: relative;

        &, span {
            text-align: center;
        }

        &:after {
            content: "";
            position: absolute;
            bottom: -15px;
            left: calc(50% - 50px);
            width: 100px;
            height: 3px;
            background: $primary;
            display: block;
        }
    }

    .product-additional-info {
        .tabs {
            box-shadow: none;
            padding: 0;
            margin: 15px 0;
        }
    }

    #loyalty {
        margin-top: 15px;
    }
}