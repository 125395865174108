body {
    font-family: $font-family;

    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        font-family: $questrial;
        color: $primary;
    }

    .btn {
        white-space: normal;
    }

    .input-group .input-group-btn > .btn {
        white-space: nowrap;
    }

    &:not(#authentication):not(#contact):not(#password) {
        #adveg-grecaptcha {
            display: none;
        }
    }

    .bootstrap-touchspin .group-span-filestyle .btn-touchspin, 
    .group-span-filestyle .bootstrap-touchspin .btn-touchspin, 
    .group-span-filestyle .btn-default {
        background: $primary;

        &:hover {
            background: $primary-hover;
        }
    }

    &#cms #content-wrapper,
    &#module-psgdpr-gdpr #content-wrapper,
    &#order-slip #content-wrapper,
    &#history #content-wrapper,
    &#identity #content-wrapper,
    &#address #content-wrapper {
        @media (min-width: 768px) {
            float: left;
            width: 75%;
        }

        @media (min-width: 576px) {
            float: left;
            width: 66.66667%;
        }

        float: left;
        width: 100%;
    }

    #wrapper .breadcrumb {
        /* 
        background: url('/themes/vitacreme/assets/img/background-breadcrumb.jpg') no-repeat;
        background-size: cover;
        background-position-y: -50px;
        */

        padding: 30px 0 15px;
        margin-bottom: 15px;
        background: none;
        
        &:before {
            background: none !important;
        }

        .page_name {
            h4 {
                color: $black;
                font-size: 36px;
                line-height: 1;
            }
        }

        ol {
            li {
                &:last-child a,
                a {
                    color: $black;

                    &:hover {
                        color: $primary;
                    }
                }

                &:after {
                    color: $black;
                }
            }
        }
    }

    &#checkout #wrapper {
        padding: 30px 0;
    }

    .product-price {
        color: $primary;
    }

    b, strong {
        font-weight: 600;
    }

    #laber_add_to_compare {
        display: none;
    }

    &.page-my-account #content .links a:hover i {
        color: $primary;
    }

    .cart-grid-body a.label:hover,
    .cart-line-product-actions .remove-from-cart .material-icons:hover {
        color: $primary;
    }

    &#checkout section.checkout-step .address-item.selected {
        border-color: $primary;
    }

    .custom-radio input[type=radio]:checked+span,
    .btn-primary.disabled.focus, 
    .btn-primary.disabled:focus, 
    .btn-primary.disabled:hover, 
    .btn-primary:disabled.focus, 
    .btn-primary:disabled:focus, 
    .btn-primary:disabled:hover {
        background-color: $primary;
    }
    
    #block_myaccount_infos {
        .h3 {
            a {
                color: $primary;

                &:hover {
                    color: $primary-hover;
                }
            }
        }

        .account-list {
            li {
                a {
                    color: $gray-dark;

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }

    .elementor .elementor-text-editor {
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .hidden {
        display: none !important;
    }

    #wrapper {
        .breadcrumb {
            position: relative;
            z-index: 1;

            &:before {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba($black, .2);
                z-index: -1;
            }
        }

        @media screen and (max-width: 991px) {
            padding-top: 20px;
        }
    }

    @media screen and (min-width: 992px) {
        .block-category {
            h1 {
                display: none !important;
            }
        }
    }

    .margin-top-60 {
        margin-top: 60px;
    }

    .datatranscw-payment-form {
        display: none;
    }

    &#cart {
        #main {
            .cart-grid {
                .cart-grid-body {
                    & > p {
                        margin-top: 15px;
                    }
                }
            }

            .voucher-informations {
                margin-top: 15px;
                
                p {
                    margin-bottom: 0;
                }
            }
        }
    }
}