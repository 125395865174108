/** Darvidis variables */
/** Import google font */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600|Questrial");
@media screen and (max-width: 767px) {
  body header#header {
    margin-bottom: 0;
  }
}
body header#header .header-nav #contact-link ul li {
  padding-left: 20px;
}
body header#header .header-nav #contact-link ul li a {
  display: inline-block;
  color: #343a40;
  font-size: 14px;
  font-weight: 400;
  line-height: 46px;
  padding-top: 0;
}
body header#header .header-nav #contact-link ul li a:hover {
  color: #144598;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  body header#header .header-nav #contact-link ul li {
    margin-right: 10px;
  }
  body header#header .header-nav #contact-link ul li i {
    position: relative;
    line-height: 46px;
    margin-right: 5px;
  }
  body header#header .header-nav #contact-link ul li i.icon-call-in {
    padding-top: 0;
  }
  body header#header .header-nav #contact-link ul li:first-child {
    padding: 0;
  }
}
@media screen and (max-width: 767px) {
  body header#header .header-nav {
    background: #144598;
  }
  body header#header .header-nav .top-logo {
    padding: 15px;
  }
  body header#header .header-nav .top-logo h1, body header#header .header-nav .top-logo a, body header#header .header-nav .top-logo picture, body header#header .header-nav .top-logo img {
    width: 100%;
  }
  body header#header .header-nav #labermenu-icon {
    color: #FFF;
  }
  body header#header .header-nav .laber-cart a.cart {
    color: #FFF;
    margin-top: 20px;
  }
  body header#header .header-nav .laber-cart a.cart .cart-products-count {
    color: #f8f9fa;
  }
  body header#header .header-nav #_mobile_Search_top {
    width: calc(100% - 60px);
  }
  body header#header .header-nav #_mobile_Search_top .search-widget {
    padding-left: 0;
  }
  body header#header .header-nav #_mobile_Search_top .search-widget form input[type=text] {
    border-color: #144598;
    min-width: 0;
  }
  body header#header .header-nav .laberwishtlistTop a.wishtlist_top {
    color: #FFF;
    padding-left: 10px;
    border-left: none;
    margin-left: 0;
    margin-right: 0;
    margin-top: 24px;
    line-height: 1;
    font-size: 22px;
  }
  body header#header .header-nav .laberwishtlistTop a.wishtlist_top:before {
    content: none;
  }
  body header#header .header-nav .laberwishtlistTop a.wishtlist_top .cart-wishlist-number {
    color: #FFF;
    top: -9px;
  }
  body header#header .header-nav .laberUserInfo .laberUser p.nameuser {
    color: #FFF;
    padding: 0 10px;
    margin-right: 10px;
    line-height: 1;
  }
  body header#header .header-nav .laberUserInfo .laberUser p.nameuser .icon-user {
    position: relative;
    line-height: 1;
    font-size: 22px;
    margin-top: 24px;
    display: block;
  }
  body header#header .header-nav .laberUserInfo .laberUser p.nameuser span,
body header#header .header-nav .laberUserInfo .laberUser p.nameuser .account {
    display: none;
  }
  body header#header .header-nav .laberUserInfo .laberUser p.nameuser a {
    color: #FFF;
  }
}
@media screen and (max-width: 500px) {
  body header#header .header-nav .top-logo {
    width: 55vw;
  }
  body header#header .header-nav .laber-cart a.cart {
    margin-top: 15px;
  }
  body header#header .header-nav .laberwishtlistTop a.wishtlist_top {
    margin-top: 19px;
    font-size: 22px;
  }
  body header#header .header-nav .laberwishtlistTop a.wishtlist_top .cart-wishlist-number {
    top: -9px;
  }
  body header#header .header-nav .laberUserInfo .laberUser p.nameuser .icon-user {
    margin-top: 19px;
  }
}
@media screen and (max-width: 375px) {
  body header#header .header-nav .laber-cart a.cart {
    margin-left: 0;
  }
  body header#header .header-nav .laberUserInfo .laberUser p.nameuser {
    margin-right: 0;
  }
}
body header#header .header-top {
  background: #144598;
  color: #FFF;
}
body header#header .header-top.laberMenu-top {
  background: #144598 !important;
}
body header#header .header-top .top-logo h1, body header#header .header-top .top-logo a, body header#header .header-top .top-logo picture, body header#header .header-top .top-logo img {
  width: 100%;
}
@media screen and (min-width: 768px) {
  body header#header .header-top .lab-menu-horizontal ul li a {
    font-family: "Open Sans", sans-serif;
    color: #FFF;
  }
  body header#header .header-top .lab-menu-horizontal ul li:hover a {
    color: #ddd;
  }
}
@media screen and (max-width: 767px) {
  body header#header .header-top .lab-menu-horizontal .title-menu {
    background: #144598;
  }
}
body header#header .header-top .laberwishtlistTop a.wishtlist_top {
  color: #FFF;
  padding-left: 10px;
  border-left: none;
  margin-left: 0;
}
body header#header .header-top .laberwishtlistTop a.wishtlist_top:before {
  content: none;
}
body header#header .header-top .laberwishtlistTop a.wishtlist_top .cart-wishlist-number, body header#header .header-top .laberwishtlistTop a.wishtlist_top:hover {
  color: #ddd;
}
body header#header .header-top .laber-cart a.cart {
  color: #FFF;
}
body header#header .header-top .laber-cart a.cart:hover,
body header#header .header-top .laber-cart a.cart .cart-products-count {
  color: #ddd;
}
body header#header .header-top .laber-cart .dropdown-menu .cart-buttons a {
  margin-top: 10px;
}
body header#header .header-top .laberSearch-dropdown .expand-more {
  color: #FFF;
}
body header#header .header-top .laberSearch-dropdown .expand-more:hover {
  color: #ddd;
}
body header#header .header-top .laberUserInfo .laberUser p.nameuser {
  color: #FFF;
  padding: 0 10px;
  margin-right: 10px;
}
body header#header .header-top .laberUserInfo .laberUser p.nameuser .icon-user {
  position: relative;
}
body header#header .header-top .laberUserInfo .laberUser p.nameuser:hover,
body header#header .header-top .laberUserInfo .laberUser p.nameuser .icon-user:hover {
  color: #ddd;
}
body header#header .header-top .laberUserInfo .laberUser p.nameuser span,
body header#header .header-top .laberUserInfo .laberUser p.nameuser .account {
  display: none;
}
body header#header .header-top .laberUserInfo .laberUser p.nameuser a {
  color: #FFF;
}
body header#header .header-top .laberUserInfo .laberUser p.nameuser a:hover {
  color: #ddd;
}
@media screen and (max-width: 1409px) and (min-width: 1160px) {
  body header#header .header-top .position-static {
    max-width: 225px;
  }
  body header#header .header-top #_desktop_logo {
    max-width: 225px;
  }
}
@media screen and (max-width: 1159px) and (min-width: 768px) {
  body header#header .header-top .position-static {
    width: 33%;
  }
  body header#header .header-top #_desktop_logo {
    width: 33%;
  }
}
@media screen and (max-width: 767px) {
  body header#header .header-top .mobile_UserInfo .signin {
    background: #144598;
  }
}
body header#header .header-top:after {
  content: "";
  height: 5px;
  background-color: #144598;
  display: block;
  position: absolute;
  width: 100%;
  margin-top: 5px;
}
@media screen and (max-width: 1159px) {
  body header#header .header-top .container_lab_megamenu {
    clear: both;
  }
}
body header#header .header-top .container_lab_megamenu .displayMegamenu ul.menu-content {
  display: flex;
  width: 100%;
  position: absolute;
  justify-content: center;
}
body header#header .header-top .container_lab_megamenu .displayMegamenu ul.menu-content > li {
  float: none;
}
@media screen and (max-width: 1799px) {
  body header#header .header-top .container_lab_megamenu .displayMegamenu ul.menu-content > li {
    padding: 0 10px;
  }
}
body header#header .header-top .container_lab_megamenu .displayMegamenu ul.menu-content > li .menu-dropdown li a {
  display: none;
}
body header#header .header-top .container_lab_megamenu .displayMegamenu ul.menu-content > li > a:after {
  position: relative;
  top: 3px;
  margin-left: 5px;
}
@media screen and (max-width: 1159px) and (min-width: 768px) {
  body header#header .header-top .container_lab_megamenu .displayMegamenu ul.menu-content > li > a {
    line-height: 60px;
  }
}
body header#header .header-top .container_lab_megamenu .displayMegamenu ul.menu-content > li:hover .menu-dropdown li a {
  display: block;
  color: #333e48;
}
body header#header .header-top .container_lab_megamenu .displayMegamenu ul.menu-content > li:hover .menu-dropdown li a:hover {
  text-decoration: none;
  color: #144598;
}

body footer#footer,
body#checkout footer#footer {
  position: relative;
  background: #144598;
  color: #FFF;
  margin-top: 60px;
}
body footer#footer:before,
body#checkout footer#footer:before {
  content: "";
  height: 5px;
  background-color: #144598;
  display: block;
  position: absolute;
  width: 100%;
  top: -10px;
}
body footer#footer .links li a,
body#checkout footer#footer .links li a {
  color: #FFF;
}
body footer#footer .links li a:hover,
body#checkout footer#footer .links li a:hover {
  color: #ddd;
}
body footer#footer .displayFooterRight .footer-contact ul li p,
body footer#footer .displayFooterRight .footer-links span,
body footer#footer .displayFooterRight .footer-contact ul li p span,
body#checkout footer#footer .displayFooterRight .footer-contact ul li p,
body#checkout footer#footer .displayFooterRight .footer-links span,
body#checkout footer#footer .displayFooterRight .footer-contact ul li p span {
  color: #FFF;
}
body footer#footer .laberFooter-top,
body#checkout footer#footer .laberFooter-top {
  display: none;
}
@media screen and (max-width: 1200px) {
  body footer#footer .laberFooter-center,
body#checkout footer#footer .laberFooter-center {
    padding: 0 15px;
  }
}
body footer#footer .laberFooter-center .displayFooter,
body#checkout footer#footer .laberFooter-center .displayFooter {
  min-height: 0px;
  padding: 50px 0 30px;
}
body footer#footer .laberFooter-center #block_myaccount_infos .h3 a,
body#checkout footer#footer .laberFooter-center #block_myaccount_infos .h3 a {
  color: #FFF;
}
body footer#footer .laberFooter-center #block_myaccount_infos .h3 a:hover,
body#checkout footer#footer .laberFooter-center #block_myaccount_infos .h3 a:hover {
  color: #ddd;
}
@media screen and (max-width: 767px) {
  body footer#footer .laberFooter-center .links .title,
body#checkout footer#footer .laberFooter-center .links .title {
    border-color: #FFF;
  }
  body footer#footer .laberFooter-center .links .title span.h3,
body#checkout footer#footer .laberFooter-center .links .title span.h3 {
    color: #FFF;
    font-size: 1rem;
  }
  body footer#footer .laberFooter-center .links .title .float-xs-right .material-icons,
body#checkout footer#footer .laberFooter-center .links .title .float-xs-right .material-icons {
    color: #FFF;
  }
}
body footer#footer .laberFooter-bottom,
body#checkout footer#footer .laberFooter-bottom {
  padding: 0 0 15px;
  background: #144598;
  border-top: none;
}
body footer#footer .laberFooter-bottom .developer, body footer#footer .laberFooter-bottom .copyright,
body#checkout footer#footer .laberFooter-bottom .developer,
body#checkout footer#footer .laberFooter-bottom .copyright {
  line-height: 30px;
}
@media screen and (max-width: 1200px) {
  body footer#footer .laberFooter-bottom,
body#checkout footer#footer .laberFooter-bottom {
    padding: 0 15px 15px;
  }
}
@media screen and (max-width: 767px) {
  body footer#footer .laberFooter-bottom,
body#checkout footer#footer .laberFooter-bottom {
    text-align: center;
  }
}
body footer#footer .laberFooter-bottom a,
body#checkout footer#footer .laberFooter-bottom a {
  color: #FFF;
}
body footer#footer .laberFooter-bottom a:hover,
body#checkout footer#footer .laberFooter-bottom a:hover {
  color: #ddd;
}
body footer#footer .laberFooter-bottom .logo-payment,
body#checkout footer#footer .laberFooter-bottom .logo-payment {
  height: 30px;
  display: inline-block;
}
@media screen and (max-width: 767px) {
  body footer#footer .laberFooter-bottom .logo-payment,
body#checkout footer#footer .laberFooter-bottom .logo-payment {
    display: block;
    text-align: center;
    float: none;
    margin-top: 5px;
    height: auto;
  }
}
body footer#footer .laberFooter-bottom .logo-payment #paiementslien,
body#checkout footer#footer .laberFooter-bottom .logo-payment #paiementslien {
  height: 30px;
  display: inline-block;
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  body footer#footer .laberFooter-bottom .logo-payment #paiementslien,
body#checkout footer#footer .laberFooter-bottom .logo-payment #paiementslien {
    height: auto;
  }
}
body footer#footer .laberFooter-bottom .logo-payment #paiementslien img, body footer#footer .laberFooter-bottom .logo-payment #paiementslien span,
body#checkout footer#footer .laberFooter-bottom .logo-payment #paiementslien img,
body#checkout footer#footer .laberFooter-bottom .logo-payment #paiementslien span {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  background: #FFF;
  padding: 5px;
  border-radius: 5px;
  vertical-align: middle;
}
@media screen and (max-width: 767px) {
  body footer#footer .laberFooter-bottom .logo-payment #paiementslien img, body footer#footer .laberFooter-bottom .logo-payment #paiementslien span,
body#checkout footer#footer .laberFooter-bottom .logo-payment #paiementslien img,
body#checkout footer#footer .laberFooter-bottom .logo-payment #paiementslien span {
    margin-top: 5px;
  }
}
body footer#footer .laberFooter-bottom .logo-payment #paiementslien span,
body#checkout footer#footer .laberFooter-bottom .logo-payment #paiementslien span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #000;
  font-size: 18px;
}

body {
  font-family: "Open Sans", sans-serif;
}
body h1, body h2, body h3, body h4, body h5, body h6, body .h1, body .h2, body .h3, body .h4, body .h5, body .h6 {
  font-family: "Questrial", sans-serif;
  color: #144598;
}
body .btn {
  white-space: normal;
}
body .input-group .input-group-btn > .btn {
  white-space: nowrap;
}
body:not(#authentication):not(#contact):not(#password) #adveg-grecaptcha {
  display: none;
}
body .bootstrap-touchspin .group-span-filestyle .btn-touchspin,
body .group-span-filestyle .bootstrap-touchspin .btn-touchspin,
body .group-span-filestyle .btn-default {
  background: #144598;
}
body .bootstrap-touchspin .group-span-filestyle .btn-touchspin:hover,
body .group-span-filestyle .bootstrap-touchspin .btn-touchspin:hover,
body .group-span-filestyle .btn-default:hover {
  background: #103676;
}
body#cms #content-wrapper, body#module-psgdpr-gdpr #content-wrapper, body#order-slip #content-wrapper, body#history #content-wrapper, body#identity #content-wrapper, body#address #content-wrapper {
  float: left;
  width: 100%;
}
@media (min-width: 768px) {
  body#cms #content-wrapper, body#module-psgdpr-gdpr #content-wrapper, body#order-slip #content-wrapper, body#history #content-wrapper, body#identity #content-wrapper, body#address #content-wrapper {
    float: left;
    width: 75%;
  }
}
@media (min-width: 576px) {
  body#cms #content-wrapper, body#module-psgdpr-gdpr #content-wrapper, body#order-slip #content-wrapper, body#history #content-wrapper, body#identity #content-wrapper, body#address #content-wrapper {
    float: left;
    width: 66.66667%;
  }
}
body #wrapper .breadcrumb {
  /* 
  background: url('/themes/vitacreme/assets/img/background-breadcrumb.jpg') no-repeat;
  background-size: cover;
  background-position-y: -50px;
  */
  padding: 30px 0 15px;
  margin-bottom: 15px;
  background: none;
}
body #wrapper .breadcrumb:before {
  background: none !important;
}
body #wrapper .breadcrumb .page_name h4 {
  color: #000;
  font-size: 36px;
  line-height: 1;
}
body #wrapper .breadcrumb ol li:last-child a,
body #wrapper .breadcrumb ol li a {
  color: #000;
}
body #wrapper .breadcrumb ol li:last-child a:hover,
body #wrapper .breadcrumb ol li a:hover {
  color: #144598;
}
body #wrapper .breadcrumb ol li:after {
  color: #000;
}
body#checkout #wrapper {
  padding: 30px 0;
}
body .product-price {
  color: #144598;
}
body b, body strong {
  font-weight: 600;
}
body #laber_add_to_compare {
  display: none;
}
body.page-my-account #content .links a:hover i {
  color: #144598;
}
body .cart-grid-body a.label:hover,
body .cart-line-product-actions .remove-from-cart .material-icons:hover {
  color: #144598;
}
body#checkout section.checkout-step .address-item.selected {
  border-color: #144598;
}
body .custom-radio input[type=radio]:checked + span,
body .btn-primary.disabled.focus,
body .btn-primary.disabled:focus,
body .btn-primary.disabled:hover,
body .btn-primary:disabled.focus,
body .btn-primary:disabled:focus,
body .btn-primary:disabled:hover {
  background-color: #144598;
}
body #block_myaccount_infos .h3 a {
  color: #144598;
}
body #block_myaccount_infos .h3 a:hover {
  color: #103676;
}
body #block_myaccount_infos .account-list li a {
  color: #343a40;
}
body #block_myaccount_infos .account-list li a:hover {
  color: #144598;
}
body .elementor .elementor-text-editor p:last-child {
  margin-bottom: 0;
}
body .hidden {
  display: none !important;
}
body #wrapper .breadcrumb {
  position: relative;
  z-index: 1;
}
body #wrapper .breadcrumb:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: -1;
}
@media screen and (max-width: 991px) {
  body #wrapper {
    padding-top: 20px;
  }
}
@media screen and (min-width: 992px) {
  body .block-category h1 {
    display: none !important;
  }
}
body .margin-top-60 {
  margin-top: 60px;
}
body .datatranscw-payment-form {
  display: none;
}
body#cart #main .cart-grid .cart-grid-body > p {
  margin-top: 15px;
}
body#cart #main .voucher-informations {
  margin-top: 15px;
}
body#cart #main .voucher-informations p {
  margin-bottom: 0;
}

body#index .lab-nivoSlideshow {
  margin-bottom: 0;
}
body#index #lab-slideshow .nivo-caption .title, body#index #lab-slideshow .nivo-caption .legend, body#index #lab-slideshow .nivo-caption .description, body#index #lab-slideshow .nivo-caption .description p {
  margin-bottom: 15px;
  width: 330px;
  color: #FFF;
  font-family: "Questrial", sans-serif;
  line-height: 1.2;
}
body#index #lab-slideshow .nivo-caption .title {
  font-size: 3rem;
}
body#index #lab-slideshow .nivo-caption .legend {
  font-size: 1.4rem;
}
@media screen and (max-width: 375px) {
  body#index #lab-slideshow .nivo-caption .title, body#index #lab-slideshow .nivo-caption .legend {
    width: 100%;
  }
  body#index #lab-slideshow .nivo-caption .title {
    font-size: 2rem;
  }
}
body#index #lab-slideshow .nivo-caption .lab_description .description p span {
  color: #144598;
}
body#index #lab-slideshow .nivo-caption .readmore a {
  border-color: #144598;
  background: #144598;
}
body#index #lab-slideshow .nivo-caption .readmore a:hover {
  background-color: #103676;
  border-color: #103676;
}
@media screen and (max-width: 767px) {
  body#index #lab-slideshow {
    min-height: 270px;
  }
  body#index #lab-slideshow img {
    height: 270px !important;
    width: auto !important;
  }
}
body#index .laberProductGrid .item-inner {
  width: 100%;
}
body#index .mypresta_scrollup.open {
  bottom: 60px;
}
body#index #products-sliders .tabs {
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}
body#index #products-sliders .tabs .title {
  cursor: pointer;
  padding: 0 15px;
}
body#index #products-sliders .tabs .title h2 {
  position: relative;
  color: #343a40;
  margin-top: 10px;
}
body#index #products-sliders .tabs .title h2:after {
  content: "";
  position: absolute;
  width: calc(100% - 10px);
  bottom: -10px;
  left: 5px;
  height: 0px;
  background: #144598;
  transition: height ease 0.1s;
  opacity: 0.6;
}
body#index #products-sliders .tabs .title.active h2, body#index #products-sliders .tabs .title:hover h2 {
  color: #144598;
}
body#index #products-sliders .tabs .title.active h2:after, body#index #products-sliders .tabs .title:hover h2:after {
  height: 3px;
}
body#index #products-sliders .tabs .title.active h2:after {
  opacity: 1;
}
body#index #products-sliders #products .owl-carousel .owl-controls .owl-pagination {
  text-align: center;
  display: block;
  width: 100%;
  margin-top: 15px;
}
body#index #products-sliders #products .owl-carousel .owl-controls .owl-pagination .owl-page {
  display: inline-block;
  width: 9px;
  height: 9px;
  opacity: 0.25;
  background: #144598;
  border-radius: 50%;
  margin: 5px;
}
body#index #products-sliders #products .owl-carousel .owl-controls .owl-pagination .owl-page.active {
  opacity: 0.75;
}
body#index form.elementor-email-subscription .elementor-button {
  margin-top: 0 !important;
}
body#index .elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-image-position-aside .elementor-testimonial-details {
  text-align: center;
}

body .laberProductGrid .item-inner .item .laberActions {
  right: 20px;
  opacity: 1;
}
body .laberProductGrid .item-inner .item:hover .productName a {
  color: #144598;
}
body .laberProductList .laberCart .laberBottom:hover {
  border-color: #144598;
}
body .laberProductList .laberItem a:hover {
  border-color: #144598;
}
body .laberProductList .item .description_short.has-to-show-more {
  max-height: 200px;
  position: relative;
  overflow: hidden;
}
body .laberProductList .item .description_short.has-to-show-more .show-more {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0, rgba(255, 255, 255, 0.5) 25%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0.9));
  display: flex;
  justify-content: center;
  align-items: center;
}
body .laberProductList .item .description_short.has-to-show-more.open {
  max-height: none;
}
body .laberProductList .item .description_short.has-to-show-more.open .show-more {
  display: none;
}

body#product .breadcrumb .page_name {
  display: none;
}
body#product .description.has-to-show-more {
  max-height: 200px;
  position: relative;
  overflow: hidden;
}
body#product .description.has-to-show-more .show-more {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0, rgba(255, 255, 255, 0.5) 25%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0.9));
  display: flex;
  justify-content: center;
  align-items: center;
}
body#product .description.has-to-show-more.open {
  max-height: none;
}
body#product .description.has-to-show-more.open .show-more {
  display: none;
}
body#product .blockreassurance_product .item-product {
  margin-right: 10px;
}
body#product .title_block h3 {
  position: relative;
}
body#product .title_block h3, body#product .title_block h3 span {
  text-align: center;
}
body#product .title_block h3:after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: calc(50% - 50px);
  width: 100px;
  height: 3px;
  background: #144598;
  display: block;
}
body#product .product-additional-info .tabs {
  box-shadow: none;
  padding: 0;
  margin: 15px 0;
}
body#product #loyalty {
  margin-top: 15px;
}

body#cms #left-column ul li a {
  line-height: 1.4;
  margin-bottom: 5px;
  display: block;
}
body#cms #wrapper .breadcrumb .page_name {
  display: none;
}
body#cms #wrapper #main .page-header {
  display: none;
}