body#index {

    .lab-nivoSlideshow {
        margin-bottom: 0;
    }

    #lab-slideshow {
        .nivo-caption {
            .title, .legend, .description, .description p {
                margin-bottom: 15px;
                width: 330px;
                color: $white;
                font-family: $questrial;
                line-height: 1.2;
            }

            .title {
                font-size: 3rem;
            }

            .legend {
                font-size: 1.4rem;
            }

            @media screen and (max-width: 375px) {
                .title, .legend {
                    width: 100%;
                }

                .title {
                    font-size: 2rem;
                }
            }

            .lab_description .description p span {
                color: $primary;
            }

            .readmore {
                a {
                    border-color: $primary;
                    background: $primary;

                    &:hover {
                        background-color: $primary-hover;
                        border-color: $primary-hover;
                    }
                }
            }
        }

        @media screen and (max-width: 767px) {
            min-height: 270px;

            img {
                height: 270px !important;
                width: auto !important;
            }
        }
    }

    .laberProductGrid .item-inner {
        width: 100%;
    }

    .mypresta_scrollup.open {
        bottom: 60px;
    }

    #products-sliders {
        .tabs {
            box-shadow: none;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 10px;

            .title {
                cursor: pointer;
                padding: 0 15px;

                h2 {
                    position: relative;
                    color: $gray-dark;
                    margin-top: 10px;

                    &:after {
                        content: "";
                        position: absolute;
                        width: calc(100% - 10px);
                        bottom: -10px;
                        left: 5px;
                        height: 0px;
                        background: $primary;
                        transition: height ease .1s;
                        opacity: .6;
                    }
                }

                &.active, &:hover {
                    h2 {
                        color: $primary;

                        &:after {
                            height: 3px;
                        }
                    }
                }

                &.active {
                    h2 {
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        #products {
            .owl-carousel {
                .owl-controls {
                    .owl-pagination {
                        text-align: center;
                        display: block;
                        width: 100%;
                        margin-top: 15px;

                        .owl-page {
                            display: inline-block;
                            width: 9px;
                            height: 9px;
                            opacity: .25;
                            background: $primary;
                            border-radius: 50%;
                            margin: 5px;

                            &.active {
                                opacity: .75;
                            }
                        }
                    }
                }
            }
        }
    }

    form.elementor-email-subscription {
        .elementor-button {
            margin-top: 0 !important;
        }
    }

    .elementor-testimonial-wrapper {
        .elementor-testimonial-meta.elementor-testimonial-image-position-aside { 
            .elementor-testimonial-details {
                text-align: center;
            }
        }
    }
}